import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import './sectionFive.scss';

const SectionFive = () => {
    return (
        <div className="section-five pb-5 pt-5">
            <div className="container">
                <h1 className="section-five-heading mb-5">Services & Distribution</h1>
                <p className="main-body-text section-five-paragraph mb-5">
                    We operate through a state-of-the-art warehousing facility, with reliable transport, housing a strong locally and internationally experienced team providing objective & impartial service, fully equipped to fulfill our customer's needs in the most efficient manner.
                </p>
                <div className="row section-five-box green-box mb-5">
                    <div className="col-md-6 p-5">
                        <h2>Distribution Network</h2>
                        <p className="">An efficient distribution network is key to a successful FMCG marketing strategy. We at DPAK
                            believe that customer satisfaction depends highly on how smooth and vast our networks are
                            spread.
                        </p>
                    </div>
                    <div className="col-md-6 p-4">
                        <StaticImage
                            className="float-right"
                            src="../../../images/section-five-1.png"
                            alt=""
                        />
                    </div>
                </div>
                <div className="row section-five-box blue-box">
                    <div className="col-md-6 order-sm-1 order-md-0 p-4">
                        <StaticImage src="../../../images/section-five-2.png" alt=""/>
                    </div>
                    <div className="col-md-6 order-sm-0 order-md-1 p-5">
                        <h2>Business to Business</h2>
                        <p>For our B2B customers, we strive to provide diversity in our product and business portfolio
                            and
                            work to make business-to-business transactions smooth, efficient, and accessible. </p>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default SectionFive;
