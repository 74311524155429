import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import './sectionSix.scss';

const SectionSix = () => {
    return (
        <div className="container mt-5 mb-5">
            <div className="mb-5">
                <h2 className="d-flex justify-content-center section-six-heading">Our Hybrid Distribution Setup</h2>
                <p className="main-body-text section-six-paragraph">We have a rapidly growing customer base and a wide
                    portfolio of products backed by a
                    network of reputed suppliers and leading brands from across the globe.</p>
            </div>
            <div className="row d-flex justify-content-center section-six-boxes">
                <div className="col-md-5 mb-5 mb-md-2 section-six-box m-2">
                    <div className="row">
                        <div className="col-sm-3">
                            <StaticImage
                                className="section-six-image"
                                src="../../../images/section-six-transport.png"
                                alt=""
                                height={67}
                                width={67}
                            />
                        </div>
                        <h3 className="col-sm-9 section-six-box-heading">Transportation Network</h3>
                    </div>
                    <p className="main-body-text mt-4">
                        Nation-wide transport infrastructure to support operations with no
                        dependency on third parties
                    </p>
                </div>
                <div className="col-md-5 section-six-box-alt m-2">
                    <div className="row">
                        <div className="col-sm-3">
                            <StaticImage
                                className="section-six-image"
                                src="../../../images/section-six-technology.png"
                                alt=""
                                height={67}
                                width={67}
                            />
                        </div>
                        <h3 className="col-sm-9">State-of-the-art Technology</h3>
                    </div>
                    <p>Utilizing modern methods of communication, stock management and information management allows us to ensure smooth transactions and cater to forecasted demands in the market</p>
                </div>
            </div>
            <div className="row d-flex justify-content-center section-six-boxes">
                <div className="col-md-5 section-six-box m-2">
                    <div className="row">
                        <div className="col-sm-3">
                            <StaticImage
                                className="section-six-image"
                                src="../../../images/section-six-team.png"
                                alt=""
                                height={67}
                                width={67}
                            />
                        </div>
                        <h3 className="col-sm-9 section-six-box-heading">Professional Team</h3>
                    </div>
                    <p className="main-body-text mt-4">Well-rounded team with national and international backgrounds, prepared to access all challenges with ease
                    </p>
                </div>
                <div className="col-md-5 section-six-box m-2">
                    <div className="row">
                        <div className="col-sm-3">
                            <StaticImage
                                className="section-six-image"
                                src="../../../images/section-six-service.png"
                                alt=""
                                height={67}
                                width={67}
                            />
                        </div>
                        <h3 className="col-sm-9 section-six-box-heading">Value Added Services</h3>
                    </div>
                    <p className="main-body-text mt-4">Providing customers with the smoothest services and frequent
                        benefits</p>
                </div>
            </div>
        </div>
    );
};

export default SectionSix;
