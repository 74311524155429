import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import './sectionFour.scss';
import GreenTick from '../../svg/GreenTick';

const SectionFour = () => {
    return (
        <div className="container">
            <div className="row p-5">
                <div className="col-md-6">
                    <StaticImage
                        src="../../../images/goals_section.png"
                        alt=""
                    />
                </div>
                <div className="col-md-6 mt-5">
                    <div className="top-line-graphic"></div>
                    <div className="section-four-title">Our Goals</div>
                    <p className="main-body-text mt-3">To create an efficient two-way communication channel that aids the customer in purchasing the ideal product for them, at the best price. <br/><br/>
                        Filling a gap in the market by introducing a new, unique, or currently underrepresented product.<br/><br/>
                        To have a good relationship with customers and suppliers to ensure the best possible experience.

                    </p>
                        <div className="row mt-5">
                            <div className="col-sm-6 mb-4">
                                <GreenTick />
                                <span className="m-2 value-text">Integrity</span>
                            </div>
                            <div className="col-sm-6 mb-4">
                                <GreenTick />
                                <span className="m-2 value-text">Excellence</span>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-sm-6 mb-4">
                                    <GreenTick />
                                    <span className="m-2 value-text">Agility</span>
                                </div>
                                <div className="col-sm-6">
                                    <GreenTick />
                                    <span className="m-2 value-text">Respect</span>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default SectionFour;
