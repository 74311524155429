import * as React from "react";

function GreenTick(props) {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={12} cy={12} r={11} stroke="#75BC20" strokeWidth={2} />
            <path
                d="M5.5 13L9 18l9.5-10"
                stroke="#75BC20"
                strokeWidth={2}
                strokeLinecap="round"
            />
        </svg>
    );
}

export default GreenTick;