import React from "react";
import './sectionTwo.scss';

const SectionTwo = (props) => {
    return (
        <div className="section-two">
            <img
                className="section-two-bg"
                style={{gridArea: "1/1"}}
                src={props.sectionTwoBg}
                alt=""
            />
            <div
                style={{
                    // By using the same grid area for both, they are stacked on top of each other
                    gridArea: "1/1",
                    position: "relative",
                    // This centers the other elements inside the hero component
                    placeItems: "center",
                    display: "grid",
                }}
            >
                <div className="container section-two-content">
                    <div className="d-flex justify-content-center mb-5">
                        <h1 className="section-two-title">{props.headingOne}</h1>
                    </div>
                    <h2 className="section-two-subheading mb-5">{props.headingTwo}</h2>
                    <p className="section-two-paragraph mb-5">{props.paragraph}</p>
                </div>
            </div>
        </div>
    )
}

export default SectionTwo;