import React from "react";
import './sectionThree.scss'

const SectionThree = (props) => {
    return (
        <div className="section-three">
            <div className="container section-three-content py-5 px-4">
                <h1 className="section-three-heading">{props.heading}</h1>
                <p className="section-three-paragraph">{props.paragraph}</p>
            </div>
        </div>
    )
}

export default SectionThree