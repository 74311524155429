import * as React from "react";
import DpakLogo from "/src/images/dpak-logo.png";
import "/src/scss/index.scss";
import Layout from "/src/components/Layout/layout";
import HeroSection from '../components/HeroSection/HeroSection'
import SectionOne from "../components/Sections/SectionOne/SectionOne";
import SectionTwo from "../components/Sections/SectionTwo/SectionTwo";
import SectionThree from "../components/Sections/SectionThree/SectionThree";
import SectionFour from "../components/Sections/SectionFour/SectionFour";
import SectionFive from "../components/Sections/SectionFive/SectionFive";
import SectionSix from "../components/Sections/SectionSix/SectionSix";
import SectionSeven from "../components/Sections/SectionSeven/SectionSeven";
import BannerImage from '../images/home-page-banner.png';
import SectionOneImgOne from '../images/home-section-1.png';
import SectionOneImgTwo from '../images/home-section-2.png';
import sectionTwoBg from '../images/section-two-bg.png';

const IndexPage = () => {
    return (
        <Layout>
            <HeroSection
                bannerImage={BannerImage}
                title="We kick-start things."
                description="DPAK runs its operations in FMCG and Food and Non-Food Commodities, as part of a global group, in retail outlets in Pakistan. With a well-rounded and diverse team, and an extensive portfolio of products, it has a growing customer base in different markets and a network of well-reputed suppliers from leading brands across the globe."
                button="Learn More"
                hasButton={false}
                graphicColor="green"
            />
            <SectionOne
                titleOne="About Dpak"
                titleTwo="We are a world-class retail distribution business."
                subHeadingOne="A Global Group"
                paragraphOne={'As a retail distribution business, we are committed to providing exceptional services to our customers throughout Pakistan. \n\n Our internationally trained management team works closely with clients in developing an in-house capacity for Merchandising, Sales, Marketing, Supply Chain, and Finance at international standards. \n\n DPAK runs its operations in FMCG and Food and Non-Food Commodities, as part of a global group, in retail outlets in Pakistan.'}
                imageOne={SectionOneImgOne}
                imageTwo={SectionOneImgTwo}
                subHeadingTwo="Reputed Suppliers & Leading Brands"
                paragraphTwo={'With an extensive portfolio of products, DPAK has a growing customer base and a network of well-reputed suppliers from leading brands across the globe. \n\nWith well-rounded insights on the market from experienced individuals, we exceed in delivering value to all our stakeholders.\n\nDPAK believes in establishing long-term relationships, built on trust, integrity, dedication, and delivering consistent services at the highest standards while meeting and exceeding expectations.'}

            />
            <SectionTwo
                headingOne="Our Vision"
                headingTwo="Providing our customers with products and a communication channel, at the right price, and with optimal convenience."
                paragraph="DPAK consistently strives for improvement, excellence, and upholding the standards of prestige and credibility that we have built this FMCG business upon, through maintaining the highest standards of governance, practicing fair trade, and ensuring quality control and convenience. In addition, our experienced, courteous and professional staff is always readily available to aid customer needs, and are well equipped to educate, inspire and empower."
                sectionTwoBg={sectionTwoBg}
            />
            <SectionThree
                heading={'Our mission has always been to seek\n“Corporate Value of Higher Significance”'}
                paragraph={'We strive to be recognized as an innovative, proficient, and compassionate global group and these obligations are fulfilled by allocating resources to improve living standards, maintaining quality control and aiding prosperity by the induction of modern concepts in our practices, hence helping DPAK  reach a "Corporate Value of Higher Significance".'}
            />
            <SectionFour/>
            <SectionFive/>
            <SectionSix/>
            <SectionSeven
                heading="Exclusive Partners"
                paragraph="To expand our product portfolio and set us apart, Dpak runs it’s operations in FMCG , Food and Non-Food Commodities in both the national and international market, while being the exclusive distributor for selective brands in some regions.
"
            />
        </Layout>
    )
}

export default IndexPage
