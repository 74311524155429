import React from "react";
import './sectionOne.scss'

const SectionOne = (props) => {
    return (
        <div className="container mb-5">
            <div className="d-flex justify-content-center">
                <div className="text-center section-one-title">
                    {props.titleOne}
                </div>
            </div>
            <div className="d-flex justify-content-center mb-5">
                <div className="text-center section-one-subheading">
                    {props.titleTwo}
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-6 order-1 order-md-0 px-5 px-md-0">
                    <div className="top-line-graphic"></div>
                    <h2 className="section-one-p-title">{props.subHeadingOne}</h2>
                    <p className="main-body-text">{props.paragraphOne}</p>
                </div>
                <div className="col-md-6 order-0 mb-5 order-md-1 d-flex justify-content-center">
                    <div className="section-one-image green-image-box">
                        <img
                            src={props.imageOne}
                            alt={props.altImageOne}
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-6 d-flex justify-content-center mb-5">
                    <div className="section-one-image blue-image-box">
                        <img
                            src={props.imageTwo}
                            alt={props.altImageTwo}
                        />
                    </div>
                </div>
                <div className="col-md-6 px-5 px-md-1">
                    <div className="top-line-graphic"></div>
                    <h2 className="section-one-p-title">
                        {props.subHeadingTwo}
                    </h2>
                    <p className="main-body-text">

                        {props.paragraphTwo}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SectionOne
