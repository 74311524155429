import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import Button from '../../Button/Button';
import './sectionSeven.scss';

const SectionSeven = props => {
    return (
        <div className="container section-seven mb-5">
            <div className="row">
                <div className="col-md-6 mt-5 ms-3">
                    <h2 className="mb-3">{props.heading}</h2>
                    <p className="mb-3">{props.paragraph}</p>
                    {/*<Button label="Learn More" theme="btn-primary" />*/}
                </div>
                <div className="col-md-6 section-seven-right">
                    <StaticImage className="section-seven-image" src="../../../images/dole-logo.png" alt=""/>
                </div>
            </div>
        </div>
    );
};

export default SectionSeven;
